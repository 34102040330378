import React from "react";

// Icons
import BreadCrumb from "Common/BreadCrumb";
import TopSellingProducts from "./TopSellingProducts";
import WidgetsOrder from "./WidgetsOrder";
import ProductMarket from "./ProductMarket";
import BusinessCategories from "./BusinessCategories";


//i18n
import { withTranslation } from "react-i18next";
import { t } from 'i18next';
import { Country } from "./Countries";
import { City } from "./Cities";

const SmartPrediction = () => {

    return (
        <React.Fragment>
            <BreadCrumb title={t('Smart prediction')} pageTitle={t('Key Analytics')} />
            <h6 className="mb-4 text-15 ml-4 mr-4">{t('Demand forecast')}</h6>
            <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                <WidgetsOrder />
            </div>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Countries')}</h6>
                        <Country />
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Cities')}</h6>
                        <City />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Areas')}</h6>
                        <City />
                    </div>
                </div>
                <div className="grid grid-cols-6 2xl:grid-cols-18 gap-x-5">
                    <TopSellingProducts />
                </div>
            </div>
            <h6 className="mb-4 text-15 ml-4 mr-4">{t('Market trends')}</h6>
            <div className="grid grid-cols-12 2xl:grid-cols-18 gap-x-5">
                <ProductMarket />
                <BusinessCategories />
            </div>
        </React.Fragment >
    );
};

export default withTranslation()(SmartPrediction);
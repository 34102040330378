import useChartColors from "Common/useChartColors";
import React from "react";
import ReactApexChart from "react-apexcharts";

//i18n
import { withTranslation } from "react-i18next";
import { t } from 'i18next';

const SimplePie = ({ chartId }: any) => {
    const chartColors = useChartColors(chartId);
  
    const series = [52, 48];
    var options: any = {
      chart: {
        height: 350,
        type: 'pie',
      },
      labels: [t('Male'), t('Female')],
      colors: chartColors,
      legend: {
        position: 'bottom'
      }
    };
  
    return (
      <React.Fragment>
        <ReactApexChart
          dir="ltr"
          options={options}
          series={series || []}
          data-chart-colors='["bg-blue-500", "bg-yellow-500"]'
          id={chartId}
          className="apex-charts"
          height={350}
          type="pie"
        />
      </React.Fragment>
    );
  };

  export default SimplePie;
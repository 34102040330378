import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import BreadCrumb from 'Common/BreadCrumb';
import LocationBased from './LocationBased';
import { NegativeValuesChart } from './NegativeValuesChart';
import WidgetsOrders from './WidgetsOrders';

//i18n
import { withTranslation } from "react-i18next";
import { t } from 'i18next';
import { StackedChart } from '../TotalSales/StackedChart';
import SalesRevenue from '../TotalProfitLoss/SalesRevenue';
import ProductMarket from '../SmartPrediction/ProductMarket';
import WidgetsOrder from '../SmartPrediction/WidgetsOrder';
import { DatetimeChart } from './DatetimeChart';
import LowPerformingProducts from './LowPerformingProducts';
import TopSellingProducts from '../TotalSales/TopSellingProducts';
import BusinessCategories from '../SmartPrediction/BusinessCategories';

const Home = () => {

    const navigate = useNavigate();
    useEffect(() => navigate("/"), [navigate]);

    return (
        <React.Fragment>
            <BreadCrumb title={t('Home')} pageTitle={t('Dashboards')} />
            <div className="grid grid-cols-12 gap-x-5">
                <WidgetsOrders />
            </div>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">{t('Order Statistics and Pending Order Statistics')}</h6>
                    <NegativeValuesChart chartId="chartNegativeValues" />
                </div>
            </div>
            <h6 className="mb-4 text-15 ml-4 mr-4">{t('Demand forecast')}</h6>
            <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                <WidgetsOrder />
            </div>
            <h6 className="mb-4 text-15 ml-4 mr-4">{t('Basic selling information')}</h6>
            <div className="grid grid-cols-12 2xl:grid-cols-18 gap-x-5">
                <TopSellingProducts />
                <LowPerformingProducts />
            </div>
            <h6 className="mb-4 text-15 ml-4 mr-4">{t('Market trends')}</h6>
            <div className="grid grid-cols-12 2xl:grid-cols-18 gap-x-5">
                <ProductMarket />
                <BusinessCategories />
            </div>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">{t('Time Sales')}</h6>
                    <StackedChart chartId="chartStacked" />
                </div>
            </div>
            <SalesRevenue />
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">{t('Live Tracking')}</h6>
                    <div className="flex flex-wrap gap-2 toolbar align-items-start justify-content-center">
                        <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white" id="one_month">
                            1M
                        </button>
                        <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white" id="six_months">
                            6M
                        </button>
                        <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 active [&.active]:bg-custom-500 [&.active]:text-white" id="one_year">
                            1Y
                        </button>
                        <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white" id="ytd">
                            YTD
                        </button>
                        <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white" id="all">
                            ALL
                        </button>
                    </div>
                    <DatetimeChart chartId="dateTimeChart" />
                </div>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Home);

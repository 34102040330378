import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";
import { t } from "i18next";

const MostProfitCities = ({ chartId }: any) => {

    const chartColors = useChartColors(chartId);
    //basic bar
    const series = [{
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
    }];
    var options : any = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        colors: chartColors,
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: [t('Dhahran'), t('Tabuk'), t('Kharj'), t('Khobar'), t('Taif'), t('Madinah'), t('Makkah'),
                t('Dammam'), t('Jeddah'), t('Riyadh')
            ],
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-red-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={350}
            />
        </React.Fragment>
    );
};

export {
    MostProfitCities
};
import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";

const WidgetChart = ({ series, chartId, DataChartColor }: any) => {

    const chartColors = useChartColors(chartId);

    //sent email
    var options: any = {
        chart: {
            id: 'area-datetime',
            type: 'line',
            height: 80,
            sparkline: {
                enabled: true
            },
            zoom: {
                autoScaleYaxis: true
            }
        },
        colors: chartColors,
        stroke: {
            width: 2,
            curve: 'smooth',
        },
        dataLabels: {
            enabled: false
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={[...series]}
                data-chart-colors={DataChartColor}
                id={chartId}
                className="grow apex-charts"
                type='line'
                height={80}
            />
        </React.Fragment>
    );
};

const Widget2Chart = ({ series, chartId, DataChartColor }: any) => {

    const chartColors = useChartColors(chartId);

    //sent email
    var options: any = {
        chart: {
            id: 'area-datetime',
            type: 'bar',
            height: 80,
            sparkline: {
                enabled: true
            },
            zoom: {
                autoScaleYaxis: true
            }
        },
        colors: chartColors,
        stroke: {
            width: 2,
            curve: 'smooth',
        },
        dataLabels: {
            enabled: false
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={[...series]}
                data-chart-colors={DataChartColor}
                id={chartId}
                className="grow apex-charts"
                type='bar'
                height={80}
            />
        </React.Fragment>
    );
};

export {
    WidgetChart,
    Widget2Chart
};

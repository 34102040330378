import React from 'react';
import { Sun, Snowflake, Leaf, Flower } from 'lucide-react';
import CountUp from 'react-countup';

//i18n
import { withTranslation } from "react-i18next";
import { t } from 'i18next';

const Widgets = () => {
    return (
        <React.Fragment>
            <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-3">
                <div className="text-center card-body">
                    <div className="flex items-center justify-center mx-auto rounded-full size-14 bg-custom-100 text-custom-500 dark:bg-custom-500/20">
                        <Snowflake />
                    </div>
                    <h5 className="mt-4 mb-2">
                        <CountUp end={23.18} decimals={2} className="counter-value" />
                        %</h5>
                    <p className="text-slate-500 dark:text-zink-200">{t('Winter')}</p>
                </div>
            </div>
            <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-3">
                <div className="text-center card-body">
                    <div className="flex items-center justify-center mx-auto text-purple-500 bg-purple-100 rounded-full size-14 dark:bg-purple-500/20">
                        <Leaf />
                    </div>
                    <h5 className="mt-4 mb-2"><CountUp end={11} className="counter-value" />%</h5>
                    <p className="text-slate-500 dark:text-zink-200">{t('Autumn')}</p>
                </div>
            </div>
            <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-3">
                <div className="text-center card-body">
                    <div className="flex items-center justify-center mx-auto text-green-500 bg-green-100 rounded-full size-14 dark:bg-green-500/20">
                        <Flower />
                    </div>
                    <h5 className="mt-4 mb-2"><CountUp end={17} className="counter-value" />%</h5>
                    <p className="text-slate-500 dark:text-zink-200">{t('Spring')}</p>
                </div>
            </div>
            <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-3">
                <div className="text-center card-body">
                    <div className="flex items-center justify-center mx-auto text-red-500 bg-red-100 rounded-full size-14 dark:bg-red-500/20">
                        <Sun />
                    </div>
                    <h5 className="mt-4 mb-2"><CountUp end={35} className="counter-value" />%</h5>
                    <p className="text-slate-500 dark:text-zink-200">{t('Summer')}</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Widgets;

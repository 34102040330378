import React, { useEffect } from 'react';
import './assets/scss/themes.scss';
import RouteIndex from 'Routes/Index';

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useDispatch } from 'react-redux';
import { changeDirection } from 'slices/thunk';

// Activating fake backend
fakeBackend();

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);


function App() {

  const dispatch = useDispatch<any>();

    useEffect(() => {
        const savedLanguage = localStorage.getItem("I18N_LANGUAGE") || "en";
        const direction = savedLanguage === "ar" ? "rtl" : "ltr";
        document.documentElement.setAttribute("dir", direction);
        dispatch(changeDirection(direction));
    }, [dispatch]);
    
  return (
    <RouteIndex />
  );
}

export default App;

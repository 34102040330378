import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    userList: [],
    userGrid: [],
    errors: {}
};

const UsersSlice = createSlice({
    name: 'Users',
    initialState,
    reducers: {},
});

export default UsersSlice.reducer;
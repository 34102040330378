import React, { useEffect } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import SalesRevenue from './SalesRevenue';
import { BasicBarChart } from './SourceOfProfit';
import { DatetimeChart } from './DatetimeChart';
import TopSellingProducts from './TopSellingProducts';


//i18n
import { withTranslation } from "react-i18next";
import { t } from 'i18next';
import Widgets from './Widgets';
import { MostProfitCities } from './MostProfitCities';

const TotalProfitLoss = () => {

    return (
        <React.Fragment>
            <BreadCrumb title={t('Total profit/loss')} pageTitle={t('Key Analytics')} />
            <h6 className="mb-4 text-15 ml-4 mr-4">{t('Profit Rate')}</h6>
            <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                <Widgets />
            </div>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Source of profit')}</h6>
                        <BasicBarChart chartId="basicBar" />
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Net Profit')}</h6>
                        <div className="flex flex-wrap gap-2 toolbar align-items-start justify-content-center">
                            <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white" id="one_month">
                                1M
                            </button>
                            <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white" id="six_months">
                                6M
                            </button>
                            <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 active [&.active]:bg-custom-500 [&.active]:text-white" id="one_year">
                                1Y
                            </button>
                            <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white" id="ytd">
                                YTD
                            </button>
                            <button type="button" className="px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white" id="all">
                                ALL
                            </button>
                        </div>
                        <DatetimeChart chartId="dateTimeChart" />
                    </div>
                </div>
            </div>
            <SalesRevenue />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Most Profitable Cities')}</h6>
                        <MostProfitCities />
                    </div>
                </div>
                <div className="grid grid-cols-6 2xl:grid-cols-18 gap-x-5">
                    <TopSellingProducts />
                </div>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(TotalProfitLoss);

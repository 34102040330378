import React, { useEffect, useState } from 'react';
import { Dropdown } from 'Common/Components/Dropdown';
import { get } from "lodash";

// i18n
import i18n from "../i18n";
import languages from './languages';
import { changeHTMLAttribute } from 'slices/layouts/utils';
import { changeDirection } from 'slices/thunk';
import { useDispatch } from 'react-redux';


const LanguageDropdown = () => {
    const [selectedLang, setSelectedLang] = useState("");
    const dispatch = useDispatch<any>();

    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE") || "en";
        setSelectedLang(currentLanguage);
        updateDirection(currentLanguage);
    }, []);

    const updateDirection = (lang: string) => {
        const direction = lang === "ar" ? "rtl" : "ltr";
        changeHTMLAttribute("dir", direction);
        dispatch(changeDirection(direction)); // Dispatch to Redux store if needed
    };

    const changeLanguageAction = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("I18N_LANGUAGE", lang);
        setSelectedLang(lang);
        updateDirection(lang);
    };

    return (
        <Dropdown className="relative flex items-center h-header">
            <Dropdown.Trigger type="button" className="inline-flex justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=dark]:dark:text-zink-500 group-data-[topbar=dark]:dark:hover:text-zink-50" id="flagsDropdown" data-bs-toggle="dropdown">
                <img src={get(languages, `${selectedLang}.flag`)} alt="header-lang-img" className="h-5 rounded-sm" />
            </Dropdown.Trigger>
            <Dropdown.Content placement="bottom-end" className="absolute z-50 p-4 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[7rem] sm:min-w-[10rem] flex flex-col gap-4 dark:bg-zink-600" aria-labelledby="flagsDropdown">
                {Object.keys(languages).map(key => (
                    <a href="#!" className={`flex items-center gap-3 group/items language ${selectedLang === key ? "active" : ""}`} onClick={() => changeLanguageAction(key)} key={key}>
                        <img src={get(languages, `${key}.flag`)} alt="" className="object-cover h-4 rounded-full" />
                        <h6 className="transition-all duration-200 ease-linear font-15medium text-slate-600 dark:text-zink-200 group-hover/items:text-custom-500">{get(languages, `${key}.label`)}</h6>
                    </a>
                ))}
            </Dropdown.Content>
        </Dropdown>
    );
};

export default LanguageDropdown;

import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import Widgets from './Widgets';
import { StackedChart } from './StackedChart';
import { SplineChart } from './SplineChart';

//i18n
import { withTranslation } from "react-i18next";
import { t } from 'i18next';
import LineArea from './LineArea';
import InfoCustomers from './InfoCustomers';
import SimplePie from './SimplePie';
import MapComponent from './map';

const Customers = () => {

    return (
        <React.Fragment>
            <BreadCrumb title={t('Customers')} pageTitle={t('Key Analytics')} />
            <h6 className="mb-4 text-15 ml-4 mr-4">{t('Basic Customer Information')}</h6>
            <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                <InfoCustomers />
            </div>
            <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                <Widgets />
            </div>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">{t('Profit from the first Visit')}</h6>
                    <LineArea chartId="lineAreaChart" />
                </div>
            </div>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
            <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Visitors')}</h6>
                        <SplineChart chartId="splineChart" />
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Audience')}</h6>
                        <SimplePie chartId="simplePie" />
                    </div>
                </div>
            </div>
            <div className="card">
                    <div className="card-body">
                        <h6 className="mb-4 text-15">{t('Customers map')}</h6>
                        <MapComponent />
                    </div>
                </div>
        </React.Fragment>
    );
};

export default withTranslation()(Customers);

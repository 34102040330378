import React from 'react';
import { MapContainer, TileLayer, CircleMarker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { t } from 'i18next';

const cities = [
    // Saudi Arabia
  { city: 'Riyadh', lat: 24.7136, lng: 46.6753, customers: 500 },
  { city: 'Jeddah', lat: 21.2854, lng: 39.2376, customers: 350 },
  { city: 'Khobar', lat: 26.2990, lng: 50.2000, customers: 200 },
  { city: 'Dammam', lat: 26.4207, lng: 50.0888, customers: 300 },
  { city: 'Mecca', lat: 21.3891, lng: 39.8579, customers: 450 },
  { city: 'Medina', lat: 24.4667, lng: 39.6000, customers: 400 },
  { city: 'Abha', lat: 18.2164, lng: 42.5053, customers: 180 },
  { city: 'Tabuk', lat: 28.3830, lng: 36.5660, customers: 220 },
  { city: 'Najran', lat: 17.4932, lng: 44.1254, customers: 160 },
  { city: 'Hail', lat: 27.5114, lng: 41.6997, customers: 190 },
  { city: 'Buraidah', lat: 26.3264, lng: 43.9758, customers: 170 },
  { city: 'Jizan', lat: 16.8870, lng: 42.5561, customers: 140 },
  { city: 'Yanbu', lat: 24.0894, lng: 38.2294, customers: 130 },
  { city: 'Khamis Mushait', lat: 18.3095, lng: 42.7345, customers: 210 },
  { city: 'Dhahran', lat: 26.2980, lng: 50.1231, customers: 280 },
  { city: 'Sakaka', lat: 29.9755, lng: 40.2082, customers: 150 },
  { city: 'Arar', lat: 30.9167, lng: 41.0333, customers: 170 },
  { city: 'Al Mubarraz', lat: 25.3038, lng: 49.3741, customers: 190 },
  { city: 'Al Ahsa', lat: 25.3833, lng: 49.5833, customers: 220 },
  { city: 'Al Jubail', lat: 27.0128, lng: 49.6782, customers: 300 },
  { city: 'Al Qatif', lat: 26.6916, lng: 49.9112, customers: 260 },
  { city: 'Bisha', lat: 20.2284, lng: 42.5953, customers: 140 },
  { city: 'Ras Tanura', lat: 26.5511, lng: 50.1681, customers: 180 },
  { city: 'Qassim', lat: 26.4667, lng: 43.7833, customers: 200 },
  //UAE
  { city: 'Abu Dhabi', lat: 24.4539, lng: 54.3773, customers: 600 },
  { city: 'Dubai', lat: 25.276987, lng: 55.296249, customers: 800 },
  { city: 'Sharjah', lat: 25.346255, lng: 55.4211, customers: 300 },
  { city: 'Ajman', lat: 25.4052, lng: 55.5136, customers: 200 },
  { city: 'Ras al-Khaimah', lat: 25.7887, lng: 55.9763, customers: 150 },
  { city: 'Fujairah', lat: 25.1284, lng: 56.3265, customers: 100 },
  { city: 'Umm al-Quwain', lat: 25.5564, lng: 55.7344, customers: 120 },
  { city: 'Al Ain', lat: 24.2075, lng: 55.7447, customers: 250 },
  { city: 'Khor Fakkan', lat: 25.3334, lng: 56.3401, customers: 130 },
  { city: 'Kalba', lat: 25.0833, lng: 56.2833, customers: 110 },
  // Qatar
  { city: 'Doha', lat: 25.276987, lng: 51.520008, customers: 1200 },
  { city: 'Al Rayyan', lat: 25.2910, lng: 51.4454, customers: 800 },
  { city: 'Al Wakrah', lat: 25.1738, lng: 51.6181, customers: 500 },
  { city: 'Umm Salal', lat: 25.4117, lng: 51.2161, customers: 300 },
  { city: 'Al Khor', lat: 25.6833, lng: 51.5667, customers: 400 },
  { city: 'Mesaieed', lat: 24.9824, lng: 51.5831, customers: 350 },
  { city: 'Lusail', lat: 25.3601, lng: 51.4410, customers: 450 },
  { city: 'Qatar University', lat: 25.3747, lng: 51.4393, customers: 150 },
  { city: 'Al Shahaniya', lat: 25.3587, lng: 51.3616, customers: 250 },
  { city: 'Al Zubarah', lat: 25.9862, lng: 51.0901, customers: 200 },
  { city: 'Ras Laffan', lat: 25.7074, lng: 51.2025, customers: 180 },
  { city: 'Dukhan', lat: 25.7944, lng: 50.7804, customers: 220 },
  // Kuwait
  { city: 'Kuwait City', lat: 29.3759, lng: 47.9774, customers: 1000 },
  { city: 'Hawalli', lat: 29.3403, lng: 48.0034, customers: 800 },
  { city: 'Salmiya', lat: 29.3350, lng: 48.0700, customers: 700 },
  { city: 'Jahra', lat: 29.3617, lng: 47.7071, customers: 600 },
  { city: 'Fahaheel', lat: 29.0750, lng: 48.7619, customers: 500 },
  { city: 'Ahmadi', lat: 29.1305, lng: 48.0654, customers: 450 },
  { city: 'Mubarak Al-Kabeer', lat: 29.3833, lng: 48.0000, customers: 400 },
  { city: 'Khaitan', lat: 29.2904, lng: 47.9864, customers: 350 },
  { city: 'Farwaniyah', lat: 29.2635, lng: 47.9747, customers: 300 },
  { city: 'Sabah Al-Salem', lat: 29.2696, lng: 48.0193, customers: 280 },
  { city: 'Mubarak Al-Abdullah', lat: 29.3053, lng: 48.0144, customers: 260 },
  { city: 'Kuwait University', lat: 29.3400, lng: 47.9830, customers: 250 },
  { city: 'Shuwaikh', lat: 29.3341, lng: 47.9748, customers: 240 },
  { city: 'Jaber Al-Ali', lat: 29.2442, lng: 48.0790, customers: 230 },
  { city: 'Al-Rai', lat: 29.2915, lng: 47.9790, customers: 220 },
  { city: 'Al-Adan', lat: 29.1194, lng: 48.0753, customers: 210 },
  // Oman
  { city: 'Muscat', lat: 23.6139, lng: 58.5922, customers: 500 }, 
  { city: 'Salalah', lat: 17.0060, lng: 54.0927, customers: 300 },
  { city: 'Sohar', lat: 24.3667, lng: 56.5833, customers: 250 },
  { city: 'Nizwa', lat: 22.9333, lng: 57.5333, customers: 200 },
  { city: 'Ibri', lat: 23.2333, lng: 53.4167, customers: 180 },
  { city: 'Buraimi', lat: 24.4667, lng: 55.9167, customers: 170 },
  { city: 'Rustaq', lat: 23.4167, lng: 57.5833, customers: 160 },
  { city: 'Khasab', lat: 26.1750, lng: 56.2560, customers: 150 }, 
  { city: 'Manah', lat: 22.8500, lng: 57.2833, customers: 140 },
  { city: 'Sur', lat: 22.5667, lng: 59.5297, customers: 130 },
  { city: 'Dhofar', lat: 17.0100, lng: 54.0900, customers: 120 },
  // Bahrain
  { city: 'Manama', lat: 26.2025, lng: 50.5860, customers: 600 },
  { city: 'Riffa', lat: 26.0928, lng: 50.5564, customers: 450 },
  { city: 'Muharraq', lat: 25.9642, lng: 50.6232, customers: 350 },
  { city: 'Sitra', lat: 26.2038, lng: 50.6284, customers: 300 },
  { city: 'Budaiya', lat: 26.2125, lng: 50.6031, customers: 220 },
  { city: 'Isa Town', lat: 26.1865, lng: 50.5518, customers: 270 },
  { city: 'Hamad Town', lat: 26.2083, lng: 50.5833, customers: 200 },
  { city: 'Jidhafs', lat: 26.2075, lng: 50.5800, customers: 180 },
  { city: 'Sanabis', lat: 26.2208, lng: 50.5863, customers: 160 },
  { city: 'Qudaibiya', lat: 26.2074, lng: 50.5716, customers: 150 },
  { city: 'A’ali', lat: 26.1877, lng: 50.5903, customers: 130 },
  { city: 'Saar', lat: 26.1822, lng: 50.5847, customers: 140 },
  { city: 'Janabiyah', lat: 26.1881, lng: 50.6166, customers: 110 },
  // Egypt
  { city: 'Cairo', lat: 30.0444, lng: 31.2357, customers: 1000 },
  { city: 'Alexandria', lat: 31.2001, lng: 29.9187, customers: 800 },
  { city: 'Giza', lat: 30.0131, lng: 31.2089, customers: 600 },
  { city: 'Shubra El Kheima', lat: 30.1198, lng: 31.2613, customers: 400 },
  { city: 'Port Said', lat: 31.2653, lng: 32.3004, customers: 350 },
  { city: 'Suez', lat: 29.9669, lng: 32.5481, customers: 300 },
  { city: 'Luxor', lat: 25.6872, lng: 32.6396, customers: 250 },
  { city: 'Aswan', lat: 24.0889, lng: 32.8998, customers: 200 },
  { city: 'Ismailia', lat: 30.5852, lng: 32.2678, customers: 220 },
  { city: 'Tanta', lat: 30.7734, lng: 31.0181, customers: 180 },
  { city: 'Beni Suef', lat: 29.0786, lng: 31.0982, customers: 150 },
  { city: 'Mansoura', lat: 31.0377, lng: 31.3790, customers: 170 },
  { city: 'Minya', lat: 28.1015, lng: 30.7584, customers: 160 },
  { city: 'Damanhur', lat: 31.0354, lng: 30.4608, customers: 140 },
  { city: 'Qena', lat: 26.1616, lng: 32.7166, customers: 130 },
  { city: 'Sohag', lat: 26.5580, lng: 31.6944, customers: 120 },
  { city: 'Asyut', lat: 27.1822, lng: 31.1833, customers: 110 },
  { city: 'Kafr El Sheikh', lat: 31.1040, lng: 30.9330, customers: 100 },
  { city: '6th of October City', lat: 29.9716, lng: 30.9136, customers: 90 },
  { city: 'New Cairo', lat: 30.0604, lng: 31.4990, customers: 80 },
  { city: 'Nasr City', lat: 30.0753, lng: 31.3092, customers: 70 },
  { city: 'October 6th City', lat: 29.9716, lng: 30.9136, customers: 60 },
  // Yemen
  { city: 'Sana\'a', lat: 15.3694, lng: 44.1910, customers: 1000 },
  { city: 'Aden', lat: 12.7853, lng: 45.0238, customers: 800 },
  { city: 'Taiz', lat: 13.5768, lng: 44.0207, customers: 600 },
  { city: 'Hodeidah', lat: 14.7972, lng: 42.9545, customers: 550 },
  { city: 'Ibb', lat: 13.9614, lng: 45.3341, customers: 400 },
  { city: 'Mokha', lat: 13.3167, lng: 43.2167, customers: 300 },
  { city: 'Dhamar', lat: 14.5417, lng: 44.2250, customers: 250 },
  { city: 'Al Hudaydah', lat: 14.7972, lng: 42.9545, customers: 300 },
  { city: 'Zabid', lat: 14.1061, lng: 43.3316, customers: 200 },
  { city: 'Marib', lat: 15.4686, lng: 45.3568, customers: 180 },
  { city: 'Lahij', lat: 13.1333, lng: 44.7167, customers: 150 },
  { city: 'Mukalla', lat: 15.5000, lng: 49.1667, customers: 170 },
  { city: 'Sayun', lat: 15.9742, lng: 48.7921, customers: 140 },
  { city: 'Al Mahwit', lat: 15.2464, lng: 43.4396, customers: 130 },
  { city: 'Rada', lat: 14.0500, lng: 44.2000, customers: 120 },
  { city: 'Baidha', lat: 14.0794, lng: 45.6331, customers: 110 },
  { city: 'Hajjah', lat: 15.6897, lng: 43.6967, customers: 100 },
  { city: 'Al Bayda', lat: 14.8900, lng: 45.7150, customers: 90 },
  { city: 'Al Mukalla', lat: 15.5000, lng: 49.1667, customers: 85 },
  { city: 'Amran', lat: 15.7378, lng: 43.9314, customers: 80 },
  { city: 'Al Khawkhah', lat: 14.0600, lng: 43.4300, customers: 70 },
  // Jordan
  { city: 'Amman', lat: 31.9454, lng: 35.9284, customers: 500 },
  { city: 'Irbid', lat: 32.5555, lng: 35.8552, customers: 350 },
  { city: 'Zarqa', lat: 32.0664, lng: 36.0932, customers: 300 },
  { city: 'Aqaba', lat: 29.5269, lng: 35.0154, customers: 200 },
  { city: 'Madaba', lat: 31.7167, lng: 35.7833, customers: 150 },
  { city: 'Jerash', lat: 32.2725, lng: 35.8919, customers: 120 },
  { city: 'Salt', lat: 32.0333, lng: 35.7500, customers: 130 },
  { city: 'Karak', lat: 31.1815, lng: 35.7351, customers: 140 },
  { city: 'Mafraq', lat: 32.3751, lng: 36.2091, customers: 110 },
  { city: 'Tafilah', lat: 30.8327, lng: 35.6042, customers: 90 },
  { city: 'Russeifa', lat: 32.0612, lng: 36.0842, customers: 200 },
  { city: 'Fuhays', lat: 31.9833, lng: 35.8333, customers: 80 },
  { city: 'Hesban', lat: 31.9019, lng: 35.7578, customers: 70 },
  { city: 'Maan', lat: 30.1955, lng: 35.7333, customers: 60 },
  { city: 'Wadi Musa', lat: 30.3225, lng: 35.4744, customers: 130 },
  { city: 'Qatraneh', lat: 30.0380, lng: 35.5842, customers: 50 },
  // Syria
  { city: 'Damascus', lat: 33.5138, lng: 36.2760, customers: 500 },
  { city: 'Aleppo', lat: 37.0662, lng: 42.5880, customers: 450 },
  { city: 'Homs', lat: 34.7342, lng: 36.7213, customers: 300 },
  { city: 'Latakia', lat: 35.5186, lng: 35.7821, customers: 250 },
  { city: 'Daraa', lat: 32.6228, lng: 36.0965, customers: 180 },
  { city: 'Tartus', lat: 34.8830, lng: 35.8830, customers: 220 },
  { city: 'Hasaka', lat: 36.5019, lng: 40.7569, customers: 200 },
  { city: 'Raqqa', lat: 35.9624, lng: 39.0213, customers: 170 },
  { city: 'Deir ez-Zor', lat: 35.3311, lng: 40.2268, customers: 190 },
  { city: 'Qamishli', lat: 37.0352, lng: 41.2268, customers: 160 },
  { city: 'Suweida', lat: 32.6330, lng: 36.5000, customers: 150 },
  { city: 'Hama', lat: 35.1333, lng: 36.7500, customers: 180 },
  { city: 'Idlib', lat: 35.9348, lng: 36.6351, customers: 140 },
  { city: 'Rableh', lat: 34.5500, lng: 36.7667, customers: 130 },
  { city: 'Zabadani', lat: 33.7167, lng: 36.0167, customers: 120 },
  // Libya 
  { city: 'Tripoli', lat: 32.8872, lng: 13.1913, customers: 500 },
  { city: 'Benghazi', lat: 32.0836, lng: 20.0664, customers: 350 },
  { city: 'Misrata', lat: 32.3755, lng: 15.0934, customers: 300 },
  { city: 'Tamzrat', lat: 32.0515, lng: 13.0968, customers: 150 },
  { city: 'Al Khums', lat: 32.6492, lng: 14.1936, customers: 200 },
  { city: 'Sabratha', lat: 32.0836, lng: 12.2087, customers: 180 },
  { city: 'Zliten', lat: 32.4950, lng: 14.2278, customers: 220 },
  { city: 'Ajdabiya', lat: 30.7500, lng: 20.2167, customers: 170 },
  { city: 'Sebha', lat: 27.0390, lng: 14.4246, customers: 160 },
  { city: 'Derna', lat: 32.7664, lng: 22.6367, customers: 140 },
  { city: 'Jalu', lat: 29.1792, lng: 21.2778, customers: 130 },
  { city: 'Ghat', lat: 25.1498, lng: 10.1578, customers: 110 },
  { city: 'Wadi Ashshati', lat: 26.4389, lng: 14.7153, customers: 100 },
  { city: 'Murgub', lat: 31.5167, lng: 13.8000, customers: 120 },
  { city: 'Bani Walid', lat: 31.7711, lng: 13.2156, customers: 140 },
  { city: 'Tarhuna', lat: 32.3878, lng: 13.3673, customers: 180 },
  { city: 'Yafran', lat: 32.1578, lng: 12.8664, customers: 160 },
  { city: 'Kufra', lat: 24.1850, lng: 23.3219, customers: 140 },
  { city: 'Houn', lat: 29.0096, lng: 15.4662, customers: 130 },
  { city: 'Zawiya', lat: 32.7586, lng: 12.7327, customers: 200 },
  // Morocco
  { city: 'Rabat', lat: 34.020882, lng: -6.841650, customers: 300 },
  { city: 'Casablanca', lat: 33.573110, lng: -7.589843, customers: 500 },
  { city: 'Marrakech', lat: 31.629472, lng: -7.981084, customers: 400 },
  { city: 'Fes', lat: 34.020882, lng: -5.009129, customers: 350 },
  { city: 'Tangier', lat: 35.772091, lng: -5.802764, customers: 250 },
  { city: 'Agadir', lat: 30.427755, lng: -9.598107, customers: 220 },
  { city: 'Oujda', lat: 34.688049, lng: -1.910963, customers: 180 },
  { city: 'Kenitra', lat: 34.261429, lng: -6.578497, customers: 190 },
  { city: 'Tetuán', lat: 35.566262, lng: -5.365850, customers: 160 },
  { city: 'El Jadida', lat: 33.246126, lng: -8.509208, customers: 150 },
  { city: 'Khemisset', lat: 34.068068, lng: -6.503935, customers: 140 },
  { city: 'Settat', lat: 32.888361, lng: -7.622862, customers: 130 },
  { city: 'Laayoune', lat: 27.111627, lng: -13.162777, customers: 170 },
  { city: 'Safi', lat: 32.299978, lng: -9.235835, customers: 160 },
  { city: 'Beni Mellal', lat: 32.330658, lng: -6.365690, customers: 180 },
  { city: 'Meknes', lat: 34.589026, lng: -5.558346, customers: 200 },
  { city: 'Ifrane', lat: 32.9650, lng: -5.1550, customers: 110 },
  { city: 'Ouarzazate', lat: 30.9336, lng: -6.8934, customers: 120 },
  { city: 'Dakhla', lat: 23.7278, lng: -15.9434, customers: 100 },
  { city: 'Azilal', lat: 32.3793, lng: -6.4731, customers: 90 },
  { city: 'Taza', lat: 34.2077, lng: -3.9861, customers: 140 },
  // Iraq
  { city: 'Baghdad', lat: 33.3152, lng: 44.3661, customers: 800 }, // Example data
  { city: 'Basra', lat: 30.5085, lng: 47.7834, customers: 600 },
  { city: 'Mosul', lat: 36.3403, lng: 43.1328, customers: 500 },
  { city: 'Erbil', lat: 36.1911, lng: 44.0094, customers: 450 },
  { city: 'Kirkuk', lat: 35.4626, lng: 44.3922, customers: 400 },
  { city: 'Sulaymaniyah', lat: 35.5633, lng: 45.4340, customers: 350 },
  { city: 'Najaf', lat: 32.0336, lng: 44.4260, customers: 300 },
  { city: 'Kufa', lat: 32.0532, lng: 44.4258, customers: 280 },
  { city: 'Dohuk', lat: 36.8680, lng: 42.9890, customers: 250 },
  { city: 'Ramadi', lat: 33.4237, lng: 43.4387, customers: 220 },
  { city: 'Fallujah', lat: 33.4333, lng: 43.7833, customers: 200 },
  { city: 'Hilla', lat: 32.4827, lng: 44.4268, customers: 180 },
  { city: 'Samarra', lat: 34.1994, lng: 43.8897, customers: 160 },
  { city: 'Al-Qadisiyyah', lat: 31.9654, lng: 44.4971, customers: 150 },
  { city: 'Tuz Khurmatu', lat: 34.5589, lng: 44.5644, customers: 140 },
  // Algeria
  { city: 'Algiers', lat: 36.7538, lng: 3.0588, customers: 1000 },
  { city: 'Oran', lat: 35.6978, lng: -0.6410, customers: 800 },
  { city: 'Constantine', lat: 36.3650, lng: 6.6140, customers: 600 },
  { city: 'Annaba', lat: 36.8982, lng: 7.75, customers: 550 },
  { city: 'Batna', lat: 35.5585, lng: 6.1784, customers: 400 },
  { city: 'Blida', lat: 36.4815, lng: 2.8176, customers: 350 },
  { city: 'Tlemcen', lat: 34.8760, lng: -1.3162, customers: 300 },
  { city: 'Sétif', lat: 36.1911, lng: 5.4143, customers: 450 },
  { city: 'Tizi Ouzou', lat: 36.7167, lng: 4.0333, customers: 250 },
  { city: 'Médéa', lat: 36.2667, lng: 2.7500, customers: 200 },
  { city: 'Khenchela', lat: 34.8667, lng: 7.1333, customers: 180 },
  { city: 'El Oued', lat: 33.3833, lng: 6.9833, customers: 170 },
  { city: 'Chlef', lat: 36.1667, lng: 1.3333, customers: 160 },
  { city: 'Bordj Bou Arréridj', lat: 36.0667, lng: 4.7667, customers: 150 },
  { city: 'Skikda', lat: 36.8667, lng: 6.9064, customers: 140 },
  { city: 'El Harrach', lat: 36.7528, lng: 3.1875, customers: 130 },
  { city: 'El Madania', lat: 36.7538, lng: 3.0667, customers: 120 },
  { city: 'El Tarf', lat: 36.7667, lng: 8.3167, customers: 110 },
  { city: 'Tipaza', lat: 36.5913, lng: 2.4202, customers: 100 },
  { city: 'Ouargla', lat: 31.9756, lng: 5.3190, customers: 90 },
  // Sudan
  { city: 'Khartoum', lat: 15.5007, lng: 32.5599, customers: 1000 },
  { city: 'Omdurman', lat: 15.6204, lng: 32.4874, customers: 850 },
  { city: 'Port Sudan', lat: 19.6000, lng: 37.2167, customers: 500 },
  { city: 'Nyala', lat: 12.0514, lng: 15.5110, customers: 300 },
  { city: 'El-Obeid', lat: 13.1833, lng: 30.2167, customers: 250 },
  { city: 'Kassala', lat: 15.4583, lng: 36.3889, customers: 200 },
  { city: 'Al-Jazirah', lat: 14.9645, lng: 33.1806, customers: 180 },
  { city: 'Wad Madani', lat: 14.3914, lng: 33.5292, customers: 150 },
  { city: 'Dilling', lat: 11.9333, lng: 29.6333, customers: 120 },
  { city: 'Kadugli', lat: 10.3364, lng: 29.7041, customers: 100 },
  { city: 'El-Daein', lat: 10.1900, lng: 25.7300, customers: 90 },
  { city: 'El-Fasher', lat: 13.6333, lng: 25.3667, customers: 110 },
  { city: 'Abu Hamad', lat: 19.7667, lng: 33.3833, customers: 80 },
  { city: 'Sennar', lat: 13.5833, lng: 33.5833, customers: 70 },
  { city: 'Al-Rahad', lat: 13.1167, lng: 30.6167, customers: 60 },
  { city: 'Atbara', lat: 17.6967, lng: 33.9644, customers: 130 },
  { city: 'Haya', lat: 15.5436, lng: 36.6375, customers: 140 },
  { city: 'Kosti', lat: 13.1833, lng: 32.3833, customers: 160 },
  { city: 'Gadaref', lat: 14.0400, lng: 35.3700, customers: 90 },
  // Tunis
  { city: 'Tunis', lat: 36.8065, lng: 10.1815, customers: 300 },
  { city: 'La Marsa', lat: 36.8877, lng: 10.3210, customers: 250 },
  { city: 'Carthage', lat: 36.8528, lng: 10.3304, customers: 200 },
  { city: 'El Menzah', lat: 36.8320, lng: 10.2007, customers: 180 },
  { city: 'Kram', lat: 36.8526, lng: 10.3362, customers: 220 },
  { city: 'Ezzahra', lat: 36.7863, lng: 10.2485, customers: 160 },
  { city: 'Bab Souika', lat: 36.8026, lng: 10.1792, customers: 140 },
  { city: 'El Aouina', lat: 36.8400, lng: 10.2300, customers: 190 },
  { city: 'Ben Arous', lat: 36.7575, lng: 10.2054, customers: 210 },
  { city: 'Menzah 5', lat: 36.8428, lng: 10.1892, customers: 170 },
  { city: 'La Goulette', lat: 36.8373, lng: 10.3393, customers: 180 },
  // Palestaine
  { city: 'Jerusalem', lat: 31.7683, lng: 35.2137, customers: 500 },
  { city: 'Gaza City', lat: 31.5, lng: 34.47, customers: 350 },
  { city: 'Ramallah', lat: 31.9022, lng: 35.2024, customers: 400 },
  { city: 'Hebron', lat: 31.5285, lng: 35.0982, customers: 450 },
  { city: 'Nablus', lat: 32.2221, lng: 35.2625, customers: 300 },
  { city: 'Bethlehem', lat: 31.7054, lng: 35.2024, customers: 250 },
  { city: 'Jenin', lat: 32.4667, lng: 35.2833, customers: 220 },
  { city: 'Tulkaram', lat: 32.3158, lng: 35.0261, customers: 180 },
  { city: 'Qalqilya', lat: 32.1884, lng: 35.0044, customers: 170 },
  { city: 'Salfit', lat: 32.0806, lng: 35.2875, customers: 160 },
  { city: 'Deir al-Balah', lat: 31.4181, lng: 34.3517, customers: 140 },
  { city: 'Khan Yunis', lat: 31.3526, lng: 34.2973, customers: 130 },
  { city: 'Rafah', lat: 29.9692, lng: 34.2657, customers: 120 },
  { city: 'Jabalia', lat: 31.5386, lng: 34.5417, customers: 110 },
  { city: 'Beit Lahia', lat: 31.5589, lng: 34.5751, customers: 100 },
  { city: 'Nuseirat', lat: 31.4182, lng: 34.3517, customers: 90 },
  { city: 'Dura', lat: 31.5601, lng: 35.0846, customers: 80 },
];

const Map = () => {
  return (
    <div className="relative h-[80vh] w-[80vw]">
      <MapContainer
        center={[23.8859, 45.0792]} // Center of Saudi Arabia
        zoom={3}
        minZoom={2} // Set the minimum zoom level
        maxZoom={18} // Set the maximum zoom level
        className="h-full w-full"
        style={{ zIndex: 9 }} // Set z-index to 9
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {cities.map((city, index) => (
          <CircleMarker
            key={index}
            center={[city.lat, city.lng]}
            radius={Math.sqrt(city.customers) / 2} // Adjust size based on customers
            color="blue"
            fillColor="blue"
            fillOpacity={0.5}
          >
            <Tooltip direction="bottom" offset={[0, 10]} opacity={1} className="text-center">
              <div>
                <h3 className="font-bold">{t(`${city.city}`)}</h3>
                <p>{t('Customers')}: {city.customers}</p>
              </div>
            </Tooltip>
          </CircleMarker>
        ))}
      </MapContainer>
    </div>
  );
};

export default Map;

import {Home, UsersRound, ShoppingCartIcon, ArrowUpDown, LineChartIcon, PieChart } from "lucide-react";

const menuData: any = [
    {
        id: 'home',
        label: 'Home',
        link: '/',
        icon: <Home />,
    },
            {
                id: 'analyticsdashboard',
                label: 'Total sales',
                link: '/dashboards-total-sales',
                icon: <ShoppingCartIcon />,
            },
            {
                id: 'ecommercedashboard',
                label: 'Total profit/loss',
                link: '/dashboards-total-profit-loss',
                icon: <ArrowUpDown />,
            },
            {
                id: 'emaildashboard',
                label: 'Customers',
                link: '/dashboards-customers',
                icon: <UsersRound />,
            },
            {
                id: 'socialdashboard',
                label: 'Smart prediction',
                link: '/dashboards-smart-prediction',
                icon: <LineChartIcon />,
            },
    // {
    //     label: 'Components',
    //     isTitle: true,
    // },
    // {
    //     id: "apexchart",
    //     label: 'Apexcharts',
    //     link: "/#",
    //     icon: <PieChart />,
    //     subItems: [
    //         {
    //             id: 'area',
    //             label: 'Area',
    //             link: '/charts-apex-area',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'bar',
    //             label: 'Bar',
    //             link: '/charts-apex-bar',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'boxplot',
    //             label: 'Boxplot',
    //             link: '/charts-apex-boxplot',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'bubble',
    //             label: 'Bubble',
    //             link: '/charts-apex-bubble',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'candlstick',
    //             label: 'Candlstick',
    //             link: '/charts-apex-candlstick',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'column',
    //             label: 'Column',
    //             link: '/charts-apex-column',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'funnel',
    //             label: 'Funnel',
    //             link: '/charts-apex-funnel',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'heatmap',
    //             label: 'Heatmap',
    //             link: '/charts-apex-heatmap',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'line',
    //             label: 'Line',
    //             link: '/charts-apex-line',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'mixed',
    //             label: 'Mixed',
    //             link: '/charts-apex-mixed',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'pie',
    //             label: 'Pie',
    //             link: '/charts-apex-pie',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'polar',
    //             label: 'Polar Area',
    //             link: '/charts-apex-polar',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'radar',
    //             label: 'Radar',
    //             link: '/charts-apex-radar',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'radialbar',
    //             label: 'Radialbar',
    //             link: '/charts-apex-radialbar',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'range-area',
    //             label: 'Range Area',
    //             link: '/charts-apex-range-area',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'scatter',
    //             label: 'Scatter',
    //             link: '/charts-apex-scatter',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'timelinechart',
    //             label: 'Timeline',
    //             link: '/charts-apex-timeline',
    //             parentId: "apexchart"
    //         },
    //         {
    //             id: 'treemap',
    //             label: 'Treemap',
    //             link: '/charts-apex-treemap',
    //             parentId: "apexchart"
    //         }
    //     ]
    // },
];

export { menuData };
import React, { useState } from 'react';
import Drawer from 'Common/Components/Drawer';
import { Check, X } from 'lucide-react';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import {
    changeLayout,
    // changeLayoutSemiDark,
    changeSkin,
    changeLayoutMode,
    changeDirection,
    changeLayoutContentWidth,
    changeLeftsidebarSizeType,
    changeNavigation,
    changeLeftSidebarColorType,
    changeLayoutTopbarColor
} from "../slices/thunk";
import { LAYOUT_CONTENT_WIDTH, LAYOUT_DIRECTION, LAYOUT_MODE_TYPES, LAYOUT_SKIN, LAYOUT_TOPBAR_THEME_TYPES, LAYOUT_TYPES, LEFT_NAVIGATION_TYPES, LEFT_SIDEBAR_COLOR_TYPES, LEFT_SIDEBAR_SIZE_TYPES } from 'Common/constants/layout';

const RightSidebar = ({ handleToggleDrawer, isOpen }: any) => {
    const dispatch = useDispatch<any>();
    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout: any) => ({
            layoutType: layout.layoutType,
            layoutSemiDarkType: layout.layoutSemiDarkType,
            layoutSkintype: layout.layoutSkintype,
            layoutModeType: layout.layoutModeType,
            layoutDirectionType: layout.layoutDirectionType,
            layoutContentWidthType: layout.layoutContentWidthType,
            layoutSidebarSizeType: layout.layoutSidebarSizeType,
            layoutNavigationType: layout.layoutNavigationType,
            layoutSidebarColorType: layout.layoutSidebarColorType,
            layoutTopbarColorType: layout.layoutTopbarColorType,
        })
    );
    // Inside your component
    const {
        layoutType,
        // layoutSemiDarkType,
        layoutSkintype,
        layoutModeType,
        layoutDirectionType,
        layoutContentWidthType,
        layoutSidebarSizeType,
        layoutNavigationType,
        layoutSidebarColorType,
        layoutTopbarColorType
    } = useSelector(selectLayoutProperties);

    const [isClicked, setIsClicked] = useState(layoutTopbarColorType === LAYOUT_TOPBAR_THEME_TYPES.DARK);

    const handleSemiDarkMode = () => {
        setIsClicked(!isClicked);

        if (isClicked) {
            dispatch(changeLayoutTopbarColor(LAYOUT_TOPBAR_THEME_TYPES.LIGHT));
            dispatch(changeLeftSidebarColorType(LEFT_SIDEBAR_COLOR_TYPES.LIGHT));
        } else {
            dispatch(changeLayoutTopbarColor(LAYOUT_TOPBAR_THEME_TYPES.DARK));
            dispatch(changeLeftSidebarColorType(LEFT_SIDEBAR_COLOR_TYPES.DARK));
        }
    };

    const updateHorizontalMenu = (value: any) => {
        const navbarMenu: any = document.querySelector(".app-menu");
        navbarMenu.classList.remove("group-data-[layout=horizontal]:opacity-0");
        dispatch(changeLayout(value));
    };

    return (
        <React.Fragment>
            <Drawer show={isOpen} onHide={handleToggleDrawer} id="customizerButton" drawer-end="true" className="fixed inset-y-0 flex flex-col w-full transition-transform duration-300 ease-in-out transform bg-white shadow ltr:right-0 rtl:left-0 md:w-96 z-drawer dark:bg-zink-600">
                <div className="flex justify-between p-4 border-b border-slate-200 dark:border-zink-500">
                    <div className="grow">
                        <h5 className="mb-1 text-16">Theme Customizer</h5>
                        <p className="font-normal text-slate-500 dark:text-zink-200">Choose your themes & layouts etc.</p>
                    </div>
                    <div className="shrink-0">
                        <Drawer.Header data-drawer-close="customizerButton" className="transition-all duration-150 ease-linear text-slate-500 hover:text-slate-800 dark:text-zink-200 dark:hover:text-zink-50">
                            <X className="size-4"></X></Drawer.Header>
                    </div>
                </div>
                <Drawer.Body className="h-full p-6 overflow-y-auto">
                    {/* <div>
                        {/* Layout Type 
                        <h5 className="mb-3 underline capitalize text-15">Choose Layouts</h5>
                        <div className="grid grid-cols-1 mb-5 gap-7 sm:grid-cols-2">
                            <div className="relative">
                                <input
                                    id="layout-one"
                                    name="dataLayout"
                                    className="absolute size-4 border rounded-full appearance-none cursor-pointer ltr:right-2 rtl:left-2 top-2 vertical-menu-btn bg-slate-100 border-slate-300 checked:bg-custom-500 checked:border-custom-500 dark:bg-zink-400 dark:border-zink-500"
                                    type="radio"
                                    value={LAYOUT_TYPES.VERTICAL}
                                    checked={layoutType === LAYOUT_TYPES.VERTICAL}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            dispatch(changeLayout(e.target.value));
                                        }
                                    }}
                                />
                                <label className="block w-full h-24 p-0 overflow-hidden border rounded-lg cursor-pointer border-slate-200 dark:border-zink-500" htmlFor="layout-one">
                                    <span className="flex h-full gap-0">
                                        <span className="shrink-0">
                                            <span className="flex flex-col h-full gap-1 p-1 ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500">
                                                <span className="block p-1 px-2 mb-2 rounded bg-slate-100 dark:bg-zink-400"></span>
                                                <span className="block p-1 px-2 pb-0 bg-slate-100 dark:bg-zink-500"></span>
                                                <span className="block p-1 px-2 pb-0 bg-slate-100 dark:bg-zink-500"></span>
                                                <span className="block p-1 px-2 pb-0 bg-slate-100 dark:bg-zink-500"></span>
                                            </span>
                                        </span>
                                        <span className="grow">
                                            <span className="flex flex-col h-full">
                                                <span className="block h-3 bg-slate-100 dark:bg-zink-500"></span>
                                                <span className="block h-3 mt-auto bg-slate-100 dark:bg-zink-500"></span>
                                            </span>
                                        </span>
                                    </span>
                                </label>
                                <h5 className="mt-2 text-center text-15">Vertical</h5>
                            </div>

                            <div className="relative">
                                <input
                                    id="layout-two"
                                    name="dataLayout"
                                    className="absolute size-4 border rounded-full appearance-none cursor-pointer ltr:right-2 rtl:left-2 top-2 vertical-menu-btn bg-slate-100 border-slate-300 checked:bg-custom-500 checked:border-custom-500 dark:bg-zink-400 dark:border-zink-500"
                                    type="radio"
                                    value={LAYOUT_TYPES.HORIZONTAL}
                                    checked={layoutType === LAYOUT_TYPES.HORIZONTAL}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            updateHorizontalMenu(e.target.value);
                                        }
                                    }}
                                />
                                <label className="block w-full h-24 p-0 overflow-hidden border rounded-lg cursor-pointer border-slate-200 dark:border-zink-500" htmlFor="layout-two">
                                    <span className="flex flex-col h-full gap-1">
                                        <span className="flex items-center gap-1 p-1 bg-slate-100 dark:bg-zink-500">
                                            <span className="block p-1 ml-1 bg-white rounded dark:bg-zink-500"></span>
                                            <span className="block p-1 px-2 pb-0 bg-white dark:bg-zink-500 ms-auto"></span>
                                            <span className="block p-1 px-2 pb-0 bg-white dark:bg-zink-500"></span>
                                        </span>
                                        <span className="block p-1 bg-slate-100 dark:bg-zink-500"></span>
                                        <span className="block p-1 mt-auto bg-slate-100 dark:bg-zink-500"></span>
                                    </span>
                                </label>
                                <h5 className="mt-2 text-center text-15">Horizontal</h5>
                            </div>
                        </div>
                    </div> */}

                    {/* LEFT_SIDEBAR_SIZE_TYPES */}
                    {/* Sidebar Sizes */}
                    {layoutType === "vertical" && <div className="mt-6" id="sidebar-size">
                        <h5 className="mb-3 underline capitalize text-15">Sidebar Size</h5>
                        <div className="flex flex-wrap gap-3">
                            <button
                                value={LEFT_SIDEBAR_SIZE_TYPES.COMPACT}
                                onClick={() => {
                                    dispatch(changeLeftsidebarSizeType(LEFT_SIDEBAR_SIZE_TYPES.COMPACT));
                                }}
                                type="button" id="sidebarSizeTwo" name="sidebarSize" className={`transition-all duration-200 ease-linear bg-white border-dashed text-slate-500 btn border-slate-200 hover:text-slate-500 hover:bg-slate-50 hover:border-slate-200 [&.active]:text-custom-500 [&.active]:bg-custom-50 [&.active]:border-custom-200 dark:bg-zink-600 dark:text-zink-200 dark:border-zink-400 dark:hover:bg-zink-600 dark:hover:text-zink-100 dark:hover:border-zink-400 dark:[&.active]:bg-custom-500/10 dark:[&.active]:border-custom-500/30 dark:[&.active]:text-custom-500 ${layoutSidebarSizeType === LEFT_SIDEBAR_SIZE_TYPES.COMPACT ? "active" : ""}`}>Compact</button>
                            <button
                                value={LEFT_SIDEBAR_SIZE_TYPES.SMALLICON}
                                onClick={() => {
                                    dispatch(changeLeftsidebarSizeType(LEFT_SIDEBAR_SIZE_TYPES.SMALLICON));
                                }}
                                type="button" id="sidebarSizeThree" name="sidebarSize" className={`transition-all duration-200 ease-linear bg-white border-dashed text-slate-500 btn border-slate-200 hover:text-slate-500 hover:bg-slate-50 hover:border-slate-200 [&.active]:text-custom-500 [&.active]:bg-custom-50 [&.active]:border-custom-200 dark:bg-zink-600 dark:text-zink-200 dark:border-zink-400 dark:hover:bg-zink-600 dark:hover:text-zink-100 dark:hover:border-zink-400 dark:[&.active]:bg-custom-500/10 dark:[&.active]:border-custom-500/30 dark:[&.active]:text-custom-500 ${layoutSidebarSizeType === LEFT_SIDEBAR_SIZE_TYPES.SMALLICON ? "active" : ""}`}>Small (Icon)</button>
                        </div>
                    </div>}

                    {/* Navigation Types */}
                    <div className="mt-6">
                        <h5 className="mb-3 underline capitalize text-15">Navigation Type</h5>
                        <div className="flex flex-wrap gap-3">
                        <button
                                value={LEFT_NAVIGATION_TYPES.BORDERED}
                                onClick={() => {
                                    dispatch(changeNavigation(LEFT_NAVIGATION_TYPES.BORDERED));
                                }}
                                type="button" id="navbarThree" name="navbar" className={`transition-all duration-200 ease-linear bg-white border-dashed text-slate-500 btn border-slate-200 hover:text-slate-500 hover:bg-slate-50 hover:border-slate-200 [&.active]:text-custom-500 [&.active]:bg-custom-50 [&.active]:border-custom-200 dark:bg-zink-600 dark:text-zink-200 dark:border-zink-400 dark:hover:bg-zink-600 dark:hover:text-zink-100 dark:hover:border-zink-400 dark:[&.active]:bg-custom-500/10 dark:[&.active]:border-custom-500/30 dark:[&.active]:text-custom-500 ${layoutNavigationType === LEFT_NAVIGATION_TYPES.BORDERED ? "active" : ""}`}>Bordered</button>
                            <button
                                value={LEFT_NAVIGATION_TYPES.STICKY}
                                onClick={() => {
                                    dispatch(changeNavigation(LEFT_NAVIGATION_TYPES.STICKY));
                                }}
                                type="button" id="navbarTwo" name="navbar" className={`transition-all duration-200 ease-linear bg-white border-dashed text-slate-500 btn border-slate-200 hover:text-slate-500 hover:bg-slate-50 hover:border-slate-200 [&.active]:text-custom-500 [&.active]:bg-custom-50 [&.active]:border-custom-200 dark:bg-zink-600 dark:text-zink-200 dark:border-zink-400 dark:hover:bg-zink-600 dark:hover:text-zink-100 dark:hover:border-zink-400 dark:[&.active]:bg-custom-500/10 dark:[&.active]:border-custom-500/30 dark:[&.active]:text-custom-500 ${layoutNavigationType === LEFT_NAVIGATION_TYPES.STICKY ? "active" : ""}`}>Sticky</button>
                            <button
                                value={LEFT_NAVIGATION_TYPES.SCROLL}
                                onClick={() => {
                                    dispatch(changeNavigation(LEFT_NAVIGATION_TYPES.SCROLL));
                                }}
                                type="button" id="navbarOne" name="navbar" className={`transition-all duration-200 ease-linear bg-white border-dashed text-slate-500 btn border-slate-200 hover:text-slate-500 hover:bg-slate-50 hover:border-slate-200 [&.active]:text-custom-500 [&.active]:bg-custom-50 [&.active]:border-custom-200 dark:bg-zink-600 dark:text-zink-200 dark:border-zink-400 dark:hover:bg-zink-600 dark:hover:text-zink-100 dark:hover:border-zink-400 dark:[&.active]:bg-custom-500/10 dark:[&.active]:border-custom-500/30 dark:[&.active]:text-custom-500 ${layoutNavigationType === LEFT_NAVIGATION_TYPES.SCROLL ? "active" : ""}`}>Scroll</button>
                                <button
                                value={LEFT_NAVIGATION_TYPES.HIDDEN}
                                onClick={() => {
                                    dispatch(changeNavigation(LEFT_NAVIGATION_TYPES.HIDDEN));
                                }}
                                type="button" id="navbarFour" name="navbar" className={`transition-all duration-200 ease-linear bg-white border-dashed text-slate-500 btn border-slate-200 hover:text-slate-500 hover:bg-slate-50 hover:border-slate-200 [&.active]:text-custom-500 [&.active]:bg-custom-50 [&.active]:border-custom-200 dark:bg-zink-600 dark:text-zink-200 dark:border-zink-400 dark:hover:bg-zink-600 dark:hover:text-zink-100 dark:hover:border-zink-400 dark:[&.active]:bg-custom-500/10 dark:[&.active]:border-custom-500/30 dark:[&.active]:text-custom-500 ${layoutNavigationType === LEFT_NAVIGATION_TYPES.HIDDEN ? "active" : ""}`}>Hidden</button>
                        </div>
                    </div>

                    

                </Drawer.Body>
            </Drawer>
        </React.Fragment>
    );
};

export default RightSidebar;

import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { StackedChart } from './StackedChart';
import { BasicBarChart } from './BasicBarChart';
import TopSellingProducts from './TopSellingProducts';
import Widgets from 'pages/Dashboards/TotalSales/Widgets';

//i18n
import { withTranslation } from "react-i18next";
import { t } from 'i18next';

const TotalSales = () => {

  return (
    <React.Fragment>
      <BreadCrumb title={t('Total Sales')} pageTitle={t('Key Analytics')} />
      <h6 className="mb-4 text-15 ml-4 mr-4">{t('Selling Rate')}</h6>
      <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
        <Widgets />
      </div>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-15">{t('Time Sales')}</h6>
          <StackedChart chartId="chartStacked" />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
        <div className="card">
          <div className="card-body">
            <h6 className="mb-4 text-15">{t('Top selling cities')}</h6>
            <BasicBarChart chartId="basicBar" />
          </div>
        </div>
        <div className="grid grid-cols-6 2xl:grid-cols-18 gap-x-5">
          <TopSellingProducts />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(TotalSales);

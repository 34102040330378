// Dashboard
import Home from "pages/Dashboards/Home";
import TotalSales from "pages/Dashboards/TotalSales";
import TotalProfitLoss from "pages/Dashboards/TotalProfitLoss";
import Customers from "pages/Dashboards/Customers";
import SmartPrediction from "pages/Dashboards/SmartPrediction";

//Charts
import AreaCharts from "pages/ApexCharts/AreaCharts/index";
import BarCharts from "pages/ApexCharts/BarCharts";
import BoxplotCharts from "pages/ApexCharts/BoxplotCharts";
import BubbleCharts from "pages/ApexCharts/BubbleCharts";
import CandlstickCharts from "pages/ApexCharts/CandlstickCharts";
import ColumnCharts from "pages/ApexCharts/ColumnCharts";
import FunnelCharts from "pages/ApexCharts/FunnelCharts";
import HeatmapChart from "pages/ApexCharts/HeatmapChart";
import LineChart from "pages/ApexCharts/LineCharts";
import MixedChart from "pages/ApexCharts/MixedCharts/Index";
import PieChart from "pages/ApexCharts/PieCharts/Index";
import PolarAreaChart from "pages/ApexCharts/PolarAreaCharts/Index";
import RadarChart from "pages/ApexCharts/RadarCharts/Index";
import RadialbarChart from "pages/ApexCharts/RadialBarCharts/Index";
import RangeAreaChart from "pages/ApexCharts/RangeAreaCharts";
import Scatterchart from "pages/ApexCharts/ScatterCharts/Index";
import TimeLinechart from "pages/ApexCharts/TimeLineCharts/Index";
import Treemapchart from "pages/ApexCharts/TreemapCharts/Index";

// auth
import Basic from "pages/AuthenticationInner/Login/Basic";
import LoginCover from "pages/AuthenticationInner/Login/LoginCover";
import LoginBoxed from "pages/AuthenticationInner/Login/LoginBoxed";
import LoginModern from "pages/AuthenticationInner/Login/LoginModern";

//Register
import BasicRegister from "pages/AuthenticationInner/Register/Basic";
import RegisterCover from "pages/AuthenticationInner/Register/RegisterCover";
import RegisterBoxed from "pages/AuthenticationInner/Register/RegisterBoxed";
import RegisterModern from "pages/AuthenticationInner/Register/RegisterModern";

// EmailVerify
import BasicEmailVerify from "pages/AuthenticationInner/VerifyEmail/Basic";
import EmailCover from "pages/AuthenticationInner/VerifyEmail/EmailCover";
import EmailModern from "pages/AuthenticationInner/VerifyEmail/EmailModern";

// TwoSteps
import BasicTwoSteps from "pages/AuthenticationInner/TwoSteps/Basic";
import TwoStepsCover from "pages/AuthenticationInner/TwoSteps/TwoStepsCover";
import TwoStepsBoxed from "pages/AuthenticationInner/TwoSteps/TwoStepsBoxed";
import TwoStepsModern from "pages/AuthenticationInner/TwoSteps/TwoStepsModern";

// Logout
import BasicLogout from "pages/AuthenticationInner/Logout/Basic";
import LogoutCover from "pages/AuthenticationInner/Logout/LogoutCover";
import LogoutBoxed from "pages/AuthenticationInner/Logout/LogoutBoxed";
import LogoutModern from "pages/AuthenticationInner/Logout/LogoutModern";

// Reset Password
import BasicResetPassword from "pages/AuthenticationInner/ResetPassword/Basic";
import ResetPasswordCover from "pages/AuthenticationInner/ResetPassword/ResetPasswordCover";
import ResetPasswordBoxed from "pages/AuthenticationInner/ResetPassword/ResetPasswordBoxed";
import ResetPasswordModern from "pages/AuthenticationInner/ResetPassword/ResetPasswordModern";

// Create Password
import BasicCreatePassword from "pages/AuthenticationInner/CreatePassword/Basic";
import CreatePasswordModern from "pages/AuthenticationInner/CreatePassword/CreatePasswordModern";
import CreatePasswordCover from "pages/AuthenticationInner/CreatePassword/CreatePasswordCover";
import CreatePasswordBoxed from "pages/AuthenticationInner/CreatePassword/CreatePasswordBoxed";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Pages404 from "pages/AuthenticationInner/Pages404";
import Register from "pages/Authentication/Register";
import UserProfile from "pages/Authentication/UserProfile";
import ComingSoon from "pages/AuthenticationInner/ComingSoon";
import Offline from "pages/AuthenticationInner/Offline";
import Maintenance from "pages/AuthenticationInner/Maintenance"

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Home 
  { path: "/", component: Home },

  // Dashboard
  { path: "/dashboards-total-sales", component: TotalSales },
  { path: "/dashboards-total-profit-loss", component: TotalProfitLoss },
  { path: "/dashboards-customers", component: Customers },
  { path: "/dashboards-smart-prediction", component: SmartPrediction },

  //Charts
  { path: "/charts-apex-area", component: AreaCharts },
  { path: "/charts-apex-bar", component: BarCharts },
  { path: "/charts-apex-boxplot", component: BoxplotCharts },
  { path: "/charts-apex-bubble", component: BubbleCharts },
  { path: "/charts-apex-candlstick", component: CandlstickCharts },
  { path: "/charts-apex-column", component: ColumnCharts },
  { path: "/charts-apex-funnel", component: FunnelCharts },
  { path: "/charts-apex-heatmap", component: HeatmapChart },
  { path: "/charts-apex-line", component: LineChart },
  { path: "/charts-apex-mixed", component: MixedChart },
  { path: "/charts-apex-pie", component: PieChart },
  { path: "/charts-apex-polar", component: PolarAreaChart },
  { path: "/charts-apex-radar", component: RadarChart },
  { path: "/charts-apex-radialbar", component: RadialbarChart },
  { path: "/charts-apex-range-area", component: RangeAreaChart },
  { path: "/charts-apex-scatter", component: Scatterchart },
  { path: "/charts-apex-timeline", component: TimeLinechart },
  { path: "/charts-apex-treemap", component: Treemapchart },

  // profile
  { path: "/user-profile", component: UserProfile },
];

const publicRoutes = [

  // auth
  { path: "/auth-login-basic", component: Basic },
  { path: "/auth-login-cover", component: LoginCover },
  { path: "/auth-login-boxed", component: LoginBoxed },
  { path: "/auth-login-modern", component: LoginModern },

  // Register
  { path: "/auth-register-basic", component: BasicRegister },
  { path: "/auth-register-cover", component: RegisterCover },
  { path: "/auth-register-boxed", component: RegisterBoxed },
  { path: "/auth-register-modern", component: RegisterModern },

  // Verify Email
  { path: "/auth-verify-email-basic", component: BasicEmailVerify },
  { path: "/auth-verify-email-cover", component: EmailCover },
  { path: "/auth-verify-email-modern", component: EmailModern },

  // two steps
  { path: "/auth-two-steps-basic", component: BasicTwoSteps },
  { path: "/auth-two-steps-cover", component: TwoStepsCover },
  { path: "/auth-two-steps-boxed", component: TwoStepsBoxed },
  { path: "/auth-two-steps-modern", component: TwoStepsModern },

  // logout
  { path: "/auth-logout-basic", component: BasicLogout },
  { path: "/auth-logout-cover", component: LogoutCover },
  { path: "/auth-logout-boxed", component: LogoutBoxed },
  { path: "/auth-logout-modern", component: LogoutModern },

  //Reset Password
  { path: "/auth-reset-password-basic", component: BasicResetPassword },
  { path: "/auth-reset-password-cover", component: ResetPasswordCover },
  { path: "/auth-reset-password-boxed", component: ResetPasswordBoxed },
  { path: "/auth-reset-password-modern", component: ResetPasswordModern },

  //Create Password
  { path: "/auth-create-password-basic", component: BasicCreatePassword },
  { path: "/auth-create-password-cover", component: CreatePasswordCover },
  { path: "/auth-create-password-boxed", component: CreatePasswordBoxed },
  { path: "/auth-create-password-modern", component: CreatePasswordModern },

  // coming soon
  { path: "/pages-coming-soon", component: ComingSoon },

  // Error
  { path: "/pages-offline", component: Offline },
  { path: "/pages-404", component: Pages404 },

  // Maintenance
  { path: "/pages-maintenance", component: Maintenance },


  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },

]

export { authProtectedRoutes, publicRoutes };

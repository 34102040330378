import React from 'react';
import { WidgetChart } from './Charts';
import CountUp from 'react-countup';
import { widgetsData, widgetsData2 } from 'Common/data';

import { t } from 'i18next';

interface Widget {
    id: number;
    price: number;
    name: string;
    description: string;
    chartId: string;
    chartColor: string;
    decimals: number;
    suffix: string;
    series: any;
}

const Widgets: React.FC = () => {
    return (
        <React.Fragment>
            <div className="col-span-12 card 2xl:col-span-12">
                <div className="card-body">
                    <div className="grid grid-cols-1 gap-5 divide-y md:divide-x md:divide-y-0 md:grid-cols-2 2xl:grid-cols-2 rtl:divide-x-reverse divide-slate-200 dark:divide-zink-500">
                        {(widgetsData || []).map((item: Widget, key: number) => (
                            <div key={key} className="py-5 first:pt-0 md:first:pt-5 md:last:pb-5 last:pb-0 md:px-5 md:ltr:first:pl-0 md:rtl:first:pr-0 md:ltr:last:pr-0 md:rtl:last:pl-0">
                                <div className="flex mb-4">
                                    <div className="w-36 shrink-0">
                                        <p className="mb-6 uppercase text-slate-500 dark:text-zink-200">
                                            
                                            <span className="align-middle">{t(item.name)}</span>
                                        </p>
                                        <h5 className="mb-0">
                                            <CountUp end={item.price} decimals={item.decimals} suffix={item.suffix} className="counter-value" />
                                        </h5>
                                    </div>
                                    <WidgetChart series={item.series.map((seriesItem: { name: any | string | string[]; }) => ({
                                            ...seriesItem,
                                            name: t(seriesItem.name),
                                        }))} chartId={item.chartId} DataChartColor={item.chartColor} />
                                </div>
                                <p className="text-slate-500 dark:text-zink-200">{t('(Monthly)')}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Widgets;
